import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      Want to talk? Send me an email to felixinglavf@gmail.com
    </footer>
  );
};

export default Footer;
