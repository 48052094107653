import React from "react";

function Success() {
  return (
    <div>
      <article>
        <p>Thank you for your purchase :)</p>
        <p>Your order has been successfully processed.</p>
        <p>
          If you have any questions you can email us at felixinglavf@gmail.com
        </p>
      </article>
    </div>
  );
}

export default Success;
