import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import About from "./About";
import Shop from "./Shop";
import Footer from "./Footer";
import Success from "./Success";

function NavLinks() {
  const location = useLocation();
  const isRootPath = location.pathname === "/";

  return (
    <nav style={{ display: "flex", gap: "12px" }}>
      <NavLink
        to="/shop"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
      >
        Shop
      </NavLink>
      <NavLink
        to="/about"
        className={({ isActive }) =>
          isActive || isRootPath ? "active" : "inactive"
        }
      >
        About
      </NavLink>
    </nav>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <link
            rel="alternate"
            hrefLang="x-default"
            href="https://felixingla.com"
          />
        </Helmet>
        <div className="App-content">
          <header>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1>Felix Ingla</h1>
              <div style={{ flex: "1 0 0", display: "flex" }}></div>
              <NavLinks />
            </div>
          </header>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/about" element={<About />} />
            <Route path="/success" element={<Success />} />
          </Routes>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
